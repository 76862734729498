import Glide, { Controls, Autoplay } from "@glidejs/glide/dist/glide.modular.esm";

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".glide")) {
    const glide = new Glide(".glide", {
      type: "carousel",
      animationDuration: 900,
      autoplay: 8000,
    });

    glide.mount({ Controls, Autoplay });

    glide.on("move", () =>
      // The 'active' bullet gets set 'after' the full transition plays.
      // It's pretty noticeable. This just runs it at the same time
      glide._c.Controls.setActive()
    );
  }
});
