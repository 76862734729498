import "./faq.scss";

/* Smoothly scrolls to in-page anchors on click, updating URL fragment */
document.addEventListener("DOMContentLoaded", () => {
  const animateClasses = ["animate__animated", "animate__pulse"];

  const anchorLinks = document.querySelectorAll(
    "html[data-view='pages#faq'] a[href^='#'], " +
      "html[data-view='pages#faq_inventory'] a[href^='#']"
  );

  anchorLinks.forEach((link) => {
    link.addEventListener("click", (ev) => {
      const href = ev.target.href;
      const ident = href.substring(href.indexOf("#") + 1);
      const target = document.getElementById(ident);

      if (target.scrollIntoView) {
        ev.preventDefault();
        history.pushState({}, "", href);

        if (window.IntersectionObserver) {
          /* draw attention after scroll */
          const intersectionObserver = new IntersectionObserver(([entry, ..._], observer) => {
            if (entry.isIntersecting) {
              observer.unobserve(target);
              setTimeout(() => {
                target.classList.add(...animateClasses);
                setTimeout(() => {
                  target.classList.remove(...animateClasses);
                }, 1000);
              }, 500);
            }
          });
          intersectionObserver.observe(target);
        }

        target.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    });
  });
});
